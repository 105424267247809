/* eslint-disable max-lines */
import {
  AgedAlarmFilterValue,
  AlarmStatus,
  AlarmTypeFilterValue,
  CameraFilterValue,
  EmployeeFilterValue,
  FilterConfig,
  FilterValues,
  LocationAlarmStatus,
  LocationFilterValue,
  SourceEntityNameFilterValue,
  SourceSystemFilterValue,
  StatusFilterValue,
  TenantFilterValue,
  TimeFilterValue,
  TimePeriod,
} from '@hakimo-ui/hakimo/types';
import FilterSection from '../FilterSection';
import CameraFilter from '../camera-filter/CameraFilter';
import AgedAlarmFilter from '../filter-types/AgedAlarmFilter';
import AlarmTypeFilter from '../filter-types/AlarmTypeFilter';
import EmployeeFilter from '../filter-types/EmployeeFilter';
import SourceSystemFilter from '../filter-types/SourceSystemFilter';
import StatusFilter from '../filter-types/StatusFilter';
import TimeFilter from '../filter-types/TimeFilter';
import LocationFilter from '../location-filter/LocationFilter';
import SourceEntityFilter from '../source-entity-filter/SourceEntityFilter';
import TenantFilter from '../tenant-filter/TenantFilter';

interface FilterGroupProps<T extends Partial<FilterValues>> {
  configs: FilterConfig[];
  values: T;
  onChange: (values: T) => void;
  enableNegativeFilters: boolean;
}

export function FilterGroup<T extends Partial<FilterValues>>(
  props: FilterGroupProps<T>
) {
  const { configs, values, onChange, enableNegativeFilters } = props;

  return (
    <>
      {configs.map((config) => {
        const handleChange = (value: typeof config.defaultValue) => {
          onChange({ ...values, [config.type]: value });
        };
        const handleLocationCameraChange = (
          cameraValue: CameraFilterValue,
          locationValue: LocationFilterValue
        ) => {
          const newValue = {
            ...values,
            camera: cameraValue,
            location: locationValue,
          };
          onChange(newValue);
        };
        switch (config.type) {
          case 'status': {
            const value = values[config.type] as
              | StatusFilterValue<AlarmStatus>
              | StatusFilterValue<LocationAlarmStatus>;

            return (
              <FilterSection
                key={config.type}
                title={config.label ?? 'Status'}
                content={
                  <StatusFilter
                    options={config.options}
                    value={value}
                    onChange={handleChange}
                    enableNegativeFilters={enableNegativeFilters}
                  />
                }
                hasFilterApplied={value.values.length > 0}
                negativeApplied={value.negative}
              />
            );
          }
          case 'time': {
            const value = values[config.type] as TimeFilterValue;

            return (
              <FilterSection
                key={config.type}
                title={config.label ?? 'Time'}
                content={<TimeFilter value={value} onChange={handleChange} />}
                hasFilterApplied={value.period !== TimePeriod.ALL_TIME}
              />
            );
          }
          case 'agedAlarm': {
            const value = values[config.type] as AgedAlarmFilterValue;
            return (
              <FilterSection
                key={config.type}
                title={config.label ?? 'Aged Alarms'}
                info="This filter retrieves alarms that have existed for a specified duration or longer."
                content={
                  <AgedAlarmFilter value={value} onChange={handleChange} />
                }
                hasFilterApplied={value?.id !== TimePeriod.ALL_TIME}
              />
            );
          }
          case 'sourceSystem': {
            const value = values[config.type] as SourceSystemFilterValue;

            return (
              <FilterSection
                key={config.type}
                title={config.label ?? 'Source System'}
                content={
                  <SourceSystemFilter value={value} onChange={handleChange} />
                }
                hasFilterApplied={value.length > 0}
              />
            );
          }
          case 'location': {
            const value = values[config.type] as LocationFilterValue;
            const cameraFilterValue = values['camera'] as
              | CameraFilterValue
              | undefined;
            return (
              <FilterSection
                key={config.type}
                title="Location"
                content={
                  <LocationFilter
                    label={config.label ?? 'Alarm Location'}
                    value={value}
                    onChange={handleChange}
                    enableNegativeFilters={enableNegativeFilters}
                    cameraFilterValue={cameraFilterValue}
                    onCameraChange={handleLocationCameraChange}
                  />
                }
                hasFilterApplied={value.values.length > 0}
                negativeApplied={value.negative}
              />
            );
          }
          case 'type': {
            const value = values[config.type] as AlarmTypeFilterValue;

            return (
              <FilterSection
                key={config.type}
                title="Type"
                content={
                  <AlarmTypeFilter
                    label={config.label ?? 'Alarm Type'}
                    value={value}
                    onChange={handleChange}
                    enableNegativeFilters={enableNegativeFilters}
                  />
                }
                hasFilterApplied={value.values.length > 0}
                negativeApplied={value.negative}
              />
            );
          }
          case 'sourceEntity': {
            const value = values[config.type] as SourceEntityNameFilterValue;

            return (
              <FilterSection
                key={config.type}
                title="Source Entity"
                content={
                  <SourceEntityFilter
                    label={config.label ?? 'Source Entity Name'}
                    value={value}
                    onChange={handleChange}
                    enableNegativeFilters={enableNegativeFilters}
                  />
                }
                hasFilterApplied={value.values.length > 0}
                negativeApplied={value.negative}
              />
            );
          }
          case 'employee': {
            const value = values[config.type] as EmployeeFilterValue;

            return (
              <FilterSection
                key={config.type}
                title="Employee"
                content={
                  <EmployeeFilter
                    label={config.label ?? 'Employee Name'}
                    onChange={handleChange}
                    employees={value}
                  />
                }
                hasFilterApplied={value.values.length > 0}
              />
            );
          }
          case 'tenant': {
            const value = values[config.type] as TenantFilterValue;

            return (
              <FilterSection
                key={config.type}
                title="Tenant"
                content={
                  <TenantFilter
                    value={value}
                    label={config.label ?? 'Tenant'}
                    onChange={handleChange}
                    enableNegativeFilters={enableNegativeFilters}
                  />
                }
                hasFilterApplied={value.values.length > 0}
                negativeApplied={value.negative}
              />
            );
          }
          case 'camera': {
            const value = values[config.type] as CameraFilterValue;
            const locationValue = values['location'] as
              | LocationFilterValue
              | undefined;
            return (
              <FilterSection
                key={config.type}
                title="Camera"
                content={
                  <CameraFilter
                    label={config.label ?? 'Camera'}
                    value={value}
                    onChange={handleChange}
                    enableNegativeFilters={enableNegativeFilters}
                    selectedLocations={locationValue}
                  />
                }
                hasFilterApplied={value.values.length > 0}
                negativeApplied={value.negative}
              />
            );
          }
          default:
            throw new Error(`Unknown filter type`);
        }
      })}
    </>
  );
}

export default FilterGroup;
