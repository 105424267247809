/* eslint-disable max-lines */
import { useAlarmMedia, useAlarms } from '@hakimo-ui/hakimo/data-access';
import { LocationAlarm, StatusType } from '@hakimo-ui/hakimo/types';
import { withAuthz } from '@hakimo-ui/hakimo/util';
import { HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { useAtom } from 'jotai';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { viewModeAtom } from '../state';
import AlarmHeader from './alarm-header/AlarmHeader';
import AlarmUpdates from './alarm-updates/AlarmUpdates';
import AlarmVideo from './alarm-video/AlarmVideo';
import EventTimeline from './event-timeline/EventTimeline';
import { TimelineSource } from './event-timeline/types';
import Header from './header/Header';
import LiveView from './live-view/LiveView';
import { LocationAlarmContacts } from './location-alarm-contacts/LocationAlarmContacts';
import LocationFloorplan from './location-floorplan/LocationFloorplan';
import SOP from './sop/SOP';
import {
  createOrUpdateTimelineSource,
  useTimelineSources,
} from './useTimelineSources';
import { useTrackAlarmEvents } from './useTrackAlarmEvents';
import { arePropsEqual, eventsDomain, getAlarmsSearchParams } from './util';

export interface Props {
  alarm: LocationAlarm;
  onStatusChange?: (status: StatusType) => void;
  sharedToken?: string;
  showSOP?: boolean;
  showContacts?: boolean;
  showResolveAction?: boolean;
}
export function AlarmOverview(props: Props) {
  const {
    alarm: locationAlarm,
    onStatusChange,
    sharedToken,
    showSOP = true,
    showContacts = true,
    showResolveAction = true,
  } = props;
  const sortedRawAlarms = useMemo(
    () =>
      locationAlarm.rawAlarms.sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      ),
    [locationAlarm]
  );
  const { onTrackResolve, onTrackTalkdown } = useTrackAlarmEvents(
    locationAlarm.id
  );
  const latestAlarm = sortedRawAlarms[locationAlarm.rawAlarms.length - 1];
  const [selectedEvent, setSelectedEvent] = useState<{
    sourceId: string;
    eventId: string;
  }>({ sourceId: latestAlarm.sourceEntity.id, eventId: latestAlarm.id });
  const [selectedCameraId, setSelectedCameraId] = useState<string | undefined>(
    latestAlarm.sourceEntity.id
  );
  const [allRawAlarmTimelineSources, setAllRawAlarmTimelineSources] = useState<
    TimelineSource[]
  >([]);
  const [viewMode, setViewMode] = useAtom(viewModeAtom);
  const isShared = !!sharedToken;
  const { data } = useAlarmMedia({
    id: selectedEvent.eventId,
    boxes: true,
    sharedToken,
    isSharedLocationAlarm: isShared,
  });
  const timeLineSources = useTimelineSources(sortedRawAlarms);
  const [beginTime, endTime] = eventsDomain(timeLineSources);

  const cameraIds = timeLineSources.map((item) => item.id);
  const locationId = String(locationAlarm.location?.id) || '';
  const [rawAlarmsInterval, setRawAlarmsInterval] = useState<{
    startTime: number;
    endTime: number;
  }>({ startTime: beginTime, endTime: endTime });

  const isNearbyAlarmsLoaded = useMemo(() => {
    return (
      rawAlarmsInterval.startTime !== beginTime ||
      rawAlarmsInterval.endTime !== endTime
    );
  }, [
    beginTime,
    endTime,
    rawAlarmsInterval.endTime,
    rawAlarmsInterval.startTime,
  ]);

  useEffect(() => {
    // handle use case when new alarms are added to loc alarm
    // it will trigger to fetch new raw alarms as well
    if (rawAlarmsInterval.endTime < endTime) {
      setRawAlarmsInterval((prev) => ({ ...prev, endTime: endTime }));
    }
  }, [endTime, rawAlarmsInterval.endTime]);

  const queryParams = getAlarmsSearchParams(
    cameraIds,
    rawAlarmsInterval.startTime,
    rawAlarmsInterval.endTime,
    locationId,
    /*Business logic: On initial load fetch
    only resolved alarms and if user is asking for
    neighbor alarms then only fetch pending alarms*/
    isNearbyAlarmsLoaded
      ? ['Resolved by Hakimo', 'Pending']
      : ['Resolved by Hakimo']
  );
  const { data: allAlarmsData, isFetching } = useAlarms(queryParams);

  useEffect(() => {
    // for shared location alarm use raw alarm data from location alarm
    const allAlarmsItems = allAlarmsData?.items || [];
    let alarmsData = [];
    if (isShared) {
      alarmsData = [...locationAlarm.rawAlarms];
    } else if (!isNearbyAlarmsLoaded) {
      /*This case is for initial load where raw alarms only fetches resolved alarms.
      Here location alarm and raw alarms api are being clubbed together*/
      alarmsData = [...locationAlarm.rawAlarms, ...allAlarmsItems];
    } else {
      /*This case is where user has asked for
      neighbour alarms and as per search query param
      it will fetch all raw alarms including pending and resolved ones */
      alarmsData = allAlarmsItems;
    }

    if (alarmsData) {
      // once all raw alarms are fetched for the given time interval, pass into events component
      const sortedAllRawAlarms = [...alarmsData].sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      );
      const allTimelineSources = Array.from(
        sortedAllRawAlarms
          .reduce(
            createOrUpdateTimelineSource,
            new Map<string, TimelineSource>()
          )
          .values()
      );
      setAllRawAlarmTimelineSources(allTimelineSources);
    }
  }, [
    locationAlarm.rawAlarms,
    allAlarmsData?.items,
    isShared,
    isNearbyAlarmsLoaded,
  ]);

  const onNextEvent = useCallback(() => {
    const selectedTimelineSource = allRawAlarmTimelineSources.find(
      (item) => selectedEvent.sourceId === item.id
    );
    const timelinePendingEvents = selectedTimelineSource?.events.filter(
      (ev) => ev.status.toLowerCase() === 'pending'
    );

    const selectedEventIndex = timelinePendingEvents?.findIndex(
      (item) => item.id === selectedEvent.eventId
    );
    if (
      timelinePendingEvents &&
      selectedEventIndex !== undefined &&
      selectedTimelineSource
    ) {
      if (selectedEventIndex < timelinePendingEvents?.length - 1) {
        const newEvent = timelinePendingEvents[selectedEventIndex + 1];
        setSelectedEvent({
          eventId: newEvent.id,
          sourceId: selectedTimelineSource?.id,
        });
      } else {
        // this case suggests moving to next timeline source
        const timelineSourceIndex = allRawAlarmTimelineSources.findIndex(
          (item) => selectedEvent.sourceId === item.id
        );
        if (timelineSourceIndex < allRawAlarmTimelineSources.length - 1) {
          setSelectedEvent({
            sourceId: allRawAlarmTimelineSources[timelineSourceIndex + 1].id,
            eventId:
              allRawAlarmTimelineSources[timelineSourceIndex + 1].events[0].id,
          });
        }
      }
    }
  }, [
    allRawAlarmTimelineSources,
    selectedEvent.eventId,
    selectedEvent.sourceId,
  ]);

  const onPreviousEvent = () => {
    const selectedTimelineSource = allRawAlarmTimelineSources.find(
      (item) => selectedEvent.sourceId === item.id
    );
    const timelinePendingEvents = selectedTimelineSource?.events.filter(
      (ev) => ev.status.toLowerCase() === 'pending'
    );
    const selectedEventIndex = timelinePendingEvents?.findIndex(
      (item) => item.id === selectedEvent.eventId
    );
    if (
      timelinePendingEvents &&
      selectedEventIndex !== undefined &&
      selectedTimelineSource
    ) {
      if (selectedEventIndex > 0) {
        const newEvent = timelinePendingEvents[selectedEventIndex - 1];
        setSelectedEvent({
          eventId: newEvent.id,
          sourceId: selectedTimelineSource?.id,
        });
      } else {
        // this case suggests moving to previous timeline source
        const timelineSourceIndex = allRawAlarmTimelineSources.findIndex(
          (item) => selectedEvent.sourceId === item.id
        );
        if (timelineSourceIndex > 0) {
          setSelectedEvent({
            sourceId: allRawAlarmTimelineSources[timelineSourceIndex - 1].id,
            eventId:
              allRawAlarmTimelineSources[timelineSourceIndex - 1].events[0].id,
          });
        }
      }
    }
  };
  const onStatusChangeCb = (status: StatusType) => {
    onTrackResolve();
    onStatusChange && onStatusChange(status);
  };
  const onLoadNearbyAlarms = (seconds: number) => {
    const timeToChange = seconds < 0 ? 'startTime' : 'endTime';
    const dateObj = new Date(rawAlarmsInterval[timeToChange]);
    dateObj.setSeconds(dateObj.getSeconds() + seconds);
    const updatedTime = dateObj.getTime();
    setRawAlarmsInterval((prev) => ({ ...prev, [timeToChange]: updatedTime }));
  };

  const showExtraActions = useMemo(() => {
    return !isShared && (allAlarmsData?.items?.length ?? 0) > 0;
  }, [isShared, allAlarmsData?.items]);

  return (
    <div className="sticky top-0 flex h-full w-full flex-col divide-y dark:divide-white/10">
      {!isShared && (
        <Header
          alarm={locationAlarm}
          viewMode={viewMode}
          onChangeViewMode={setViewMode}
          onStatusChange={onStatusChangeCb}
          showResolveAction={showResolveAction}
        />
      )}
      <div className="space-y-4 pt-4 pl-4 pb-4">
        <div
          className={clsx(
            'flex flex-col gap-4',
            viewMode === 'split' && !isShared && 'xl:grid xl:grid-cols-2'
          )}
        >
          <div
            className="order-0 flex-1 space-y-3 xl:order-none"
            hidden={viewMode === 'live' && !isShared}
          >
            <AlarmHeader beginTime={beginTime} endTime={endTime} />
            <AlarmVideo
              videoPath={data?.videoPath}
              boxes={data?.boxes}
              onNext={onNextEvent}
              onPrevious={onPreviousEvent}
              hasPrevious={
                sortedRawAlarms.findIndex(
                  (a) => a.id === selectedEvent.eventId
                ) > 0
              }
              hasNext={
                sortedRawAlarms.findIndex(
                  (a) => a.id === selectedEvent.eventId
                ) <
                sortedRawAlarms.length - 1
              }
            />
          </div>
          {!isShared && (
            <div
              className="order-2 flex-1 space-y-4 xl:order-none"
              hidden={viewMode === 'events'}
            >
              {selectedCameraId && (
                <LiveView
                  alarms={sortedRawAlarms}
                  locationAlarmId={locationAlarm.id}
                  selectedCameraId={selectedCameraId}
                  onChangeSelectedCameraId={setSelectedCameraId}
                  onTalkdown={onTrackTalkdown}
                  location={locationAlarm.location}
                />
              )}
            </div>
          )}
          <div
            className="order-1 col-span-2 xl:order-none"
            hidden={viewMode === 'live' && !isShared}
          >
            {allRawAlarmTimelineSources.length > 0 ? (
              <EventTimeline
                sources={allRawAlarmTimelineSources}
                selected={selectedEvent.eventId}
                liveSourceId={selectedCameraId}
                isFetchingAlarms={isFetching}
                onChangeSelected={(eventId, sourceId) =>
                  setSelectedEvent({ eventId, sourceId })
                }
                onLoadNearbyAlarms={onLoadNearbyAlarms}
                rawAlarmsInterval={rawAlarmsInterval}
                showExtraActions={showExtraActions}
              />
            ) : (
              <div className="flex justify-center">
                <HakimoSpinner />
              </div>
            )}
          </div>
          {!isShared && (
            <>
              <div className="order-3 xl:order-none">
                <LocationFloorplan
                  locationId={String(locationAlarm.location.id)}
                />
              </div>
              {showSOP && (
                <div className="order-4 xl:order-none">
                  <SOP locationId={String(locationAlarm.location.id)} />
                </div>
              )}
              {showContacts && (
                <div className="order-5 xl:order-none">
                  <LocationAlarmContacts
                    locationId={locationAlarm.location.id}
                    locationAlarmId={locationAlarm.id}
                    locationTenantId={locationAlarm.location.tenant_id}
                  />
                </div>
              )}
              <div className="order-6 xl:order-none">
                <AlarmUpdates locationAlarmId={locationAlarm.id} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default memo(
  withAuthz(AlarmOverview, ['location_alarm/detail:view']),
  arePropsEqual
);
export const AlarmOverviewNoAuth = memo(AlarmOverview, arePropsEqual);
