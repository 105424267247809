import {
  AlarmStatus,
  FilterConfig,
  StatusFilterOption,
  TimePeriod,
} from '@hakimo-ui/hakimo/types';

const statusOptions: StatusFilterOption<AlarmStatus>[] = [
  { id: 'analyzing', name: 'Analyzing' },
  { id: 'pending', name: 'Pending' },
  { id: 'resolvedByHakimo', name: 'Resolved by Hakimo' },
  { id: 'resolvedManually', name: 'Resolved Manually' },
  { id: 'acsAck', name: 'Alarm Acknowledged via ACS' },
  { id: 'inProgress', name: 'In Progress' },
];

export const PAGE_SIZE = 50;
export const ALARM_QUERY_INTERVAL = 5000;

export const filterConfigs: FilterConfig[] = [
  {
    type: 'status',
    defaultValue: {
      values: [],
      negative: false,
    },
    options: statusOptions,
  },
  {
    type: 'time',
    defaultValue: {
      period: TimePeriod.PAST_24_HOURS,
      customRangeStart: '',
      customRangeEnd: '',
    },
  },
  {
    type: 'agedAlarm',
    defaultValue: { id: TimePeriod.ALL_TIME, name: TimePeriod.ALL_TIME },
  },
  {
    type: 'sourceSystem',
    defaultValue: [],
  },
  {
    type: 'location',
    defaultValue: {
      values: [],
      negative: false,
    },
  },
  {
    type: 'type',
    defaultValue: {
      values: [],
      negative: false,
    },
  },
  {
    type: 'sourceEntity',
    defaultValue: {
      values: [],
      negative: false,
    },
  },
  {
    type: 'employee',
    defaultValue: {
      values: [],
      negative: false,
    },
  },
  {
    type: 'tenant',
    defaultValue: {
      values: [],
      negative: false,
    },
  },
];

// https://observablehq.com/d/30321568fafe3e55
export const tapColors = [
  'rgb(22, 163, 74)',
  'rgb(19, 171, 18)',
  'rgb(78, 180, 15)',
  'rgb(146, 189, 10)',
  'rgb(197, 172, 6)',
  'rgb(200, 123, 7)',
  'rgb(197, 95, 12)',
  'rgb(193, 70, 17)',
  'rgb(189, 48, 23)',
  'rgb(185, 28, 28)',
];
